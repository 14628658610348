<template>
  <div v-if="!inventory_loading" class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{
        options.filename
          ? options.filename +
            (summary.total.formatted ? ': ' + summary.total.formatted + (summary.items ? " | " + $t('mvt') + ': ' + summary.items : "") : "")
          : options.inventory_type
          ? options.inventory_type
          : $tc("inventory", 1) +
            (options.inventory && options.inventory.order_id
              ? ` #${options.inventory.order_id}`
              : "")
      }}
    </p>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
      tag="div"
    >
      <form
        method="post"
        autocomplete="off"
        @submit.prevent="handleSubmit(saveInventory)"
      >
        <div class="columns is-multiline is-mobile mb-0">
          <b-input-validation
            v-model="shipping"
            class="column is-4-tablet is-12-mobile"
            rules="required"
            vid="shipping"
            :placeholder="placeholders.number.decimal_2"
            :disabled="processed"
            :suffix="currency"
            input-type="number"
            step=".01"
            :label="$t('shipping')"
            name="shipping"
            size="is-small"
          />
          <template v-if="!outsourced">
            <div class="column is-4-tablet is-12-mobile">
              <b-input-validation
                v-model="name"
                rules="required"
                vid="name"
                :placeholder="placeholders.inventory.name"
                :label="$tc('name', 1)"
                name="name"
                size="is-small"
              />
            </div>
            <div class="column is-4-tablet is-12-mobile">
              <SearchProduct
                :label="$t('add_product')"
                size="is-small"
                type="sku"
                :sites-ids="[1]"
                clearable
                :warehouse-id="options.warehouse_id"
                @selectItem="addProduct"
              />
            </div>
          </template>
        </div>

        <b-table
          :loading="inventory_loading"
          :data="items"
          class="height-auto v-align-middle"
        >
          <template v-for="h in headers">
            <b-table-column
              :key="h.value"
              v-slot="props"
              :field="h.value"
              :label="h.text"
              :sortable="h.sortable"
              :width="h.width"
              :centered="h.centered"
            >
              <template v-if="h.value == 'prod_ref_id'">
                {{ props.row.prod_ref_id }}
              </template>
              <template v-else-if="h.value == 'name'">
                {{ props.row.name }}
              </template>
              <template v-else-if="h.value == 'expiry'">
                <b-datepicker-validation
                  v-model="props.row.expiry"
                  :rules="props.row.attribute === 'flavors' ? 'required' : ''"
                  :vid="'expire_at'"
                  :disabled="props.row.attribute === 'colors' || options.done"
                  locale="fr-CA"
                  size="is-small"
                  :min-date="minDate"
                  hide-details
                  format="YYYY-MM-DD"
                  :placeholder="placeholders.date.day3"
                /> 
                <!-- <div class="is-flex">                  
                  <b-input-validation
                    v-model="props.row.expiry"
                    :rules="props.row.attribute === 'flavors' ? 'required' : ''"
                    :vid="'expire' + props.row.prod_ref_id"
                    :placeholder="placeholders.date.day3"
                    hide-details
                    size="is-small"
                    :disabled="props.row.attribute === 'colors' || !!outsourced"
                    :name="'expire' + props.row.prod_ref_id"
                  />
                  <b-datepicker
                    v-if="!options.done"
                    v-model="props.row.date"
                    :mobile-native="false"
                    :min-date="minDate"
                    position="is-bottom-left"
                    @input="setDate(props.row)"
                  >
                    <template v-slot:trigger>
                      <b-button
                        icon-left="calendar-today"
                        type="is-primary"
                        size="is-small"
                      />
                    </template>
                  </b-datepicker> 
                </div>  -->
              </template>
              <template v-else-if="h.value == 'purchase_price'">
                <b-input-validation
                  v-model="props.row.purchase_price"
                  :rules="props.row.stock_in < 0 ? '' : 'required'"
                  hide-details
                  :vid="'purchase_price_' + props.row.prod_ref_id"
                  :placeholder="placeholders.number.decimal_2"
                  size="is-small"
                  :disabled="props.row.stock_in < 0 || processed"
                  input-type="number"
                  :suffix="currency"
                  step=".01"
                />
              </template>
              <template v-else-if="h.value == 'stock_in'">
                <template v-if="outsourced">
                  {{ props.row.stock_in }}
                </template>
                <b-input-validation
                  v-else
                  v-model="props.row.stock_in"
                  :placeholder="placeholders.number[2]"
                  hide-details
                  size="is-small"
                  :rules="props.row.added ? 'required' : ''"
                  :vid="'stock_in' + props.row.prod_ref_id"
                  :disabled="!props.row.added"
                />
              </template>
              <template v-else-if="h.value == 'total'">
                <template v-if="outsourced">
                  {{ props.row.total && props.row.total.formatted || '-' }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template v-else-if="h.value == 'actions'">
                <span
                  v-if="!processed"
                  class="is-clickable"
                  @click="deleteItem(props.row)"
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                    class="baseline"
                  />
                </span>
              </template>
            </b-table-column>
          </template>
          <EmptyBlock
            slot="empty"
            icon="barcode"
          />
        </b-table>
        <div
          v-if="!processed"
          class="is-flex is-align-items-center mt-4"
        >
          <b-button
            type="is-primary"
            :disabled="save_loading || processed || items.length == 0"
            :loading="save_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
          <b-switch
            v-model="notification"
            class="ml-5"
          >
            {{
              $t("send_notifications")
            }}
          </b-switch>
        </div>
      </form>
    </ValidationObserver>

    <b-modal
      v-model="showdeleteModal"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("are_you_sure") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <p class="mb-4">
          {{ tempProductName }}
        </p>
        <div class="columns is-multiline is-mobile">
          <template v-if="TempLines.length > 1">
            <b-checkbox
              v-for="line in TempLines"
              :key="line.id"
              v-model="line.delete"
              class="column is-6-tablet is-12-mobile mr-0"
            >
              mvt: {{ line.mvt }}
            </b-checkbox>
          </template>

          <div class="column is-12">
            <b-button
              class="mr-4"
              type="is-danger"
              @click="SendDeleteRequest"
            >
              {{ $t("confirm") }}
            </b-button>
            <b-button @click="cancelDelete">
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </div>

  <b-loading
    v-else
    v-model="inventory_loading"
    :is-full-page="false"
  />
</template>

<script>
import SearchProduct from "@/components/forms/elements/searchProduct.vue";

export default {
  components: {
    SearchProduct
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showdeleteModal: false,
      outsourced: false,
      currency: null,
      processed: this.options.processed,
      filename: "",
      shipping: "",
      name: null,
      // minDate: null,

      inventory_loading: this.options.inventory_id ? true : false,
      noDataText: this.$i18n.t("no_result_found"),
      notification: true,
      save_loading: false,
      warehouses: this.options.warehouses,
      headers: [
        {
          text: this.$i18n.tc("sku", 1),
          align: "left",
          value: "prod_ref_id",
          sortable: true
        },
        {
          text: this.$i18n.tc("name", 1),
          align: "left",
          value: "name",
          sortable: true
        },
        { text: this.$i18n.t("expire_at"), align: "left", value: "expiry", width: "150px" },
        { text: this.$i18n.t("purchase_price"), align: "left", value: "purchase_price", width: "120px" },
        { text: this.$i18n.t("mvt"), align: "left", value: "stock_in", align: "left", width: "80px" },
      ],
      items: [],
      TempLines: [],
      TempLineId: null,
      tempProductName: null,
      summary: {}
    };
  },

  computed: {
    minDate: function() {
      var currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      var dateInMillis = currentDate.getTime();
      var newDate = new Date(dateInMillis);
      return newDate;
    }
  },

  mounted() {
    let warehouse = this.warehouses.find(
      w => w.id == this.options.warehouse_id
    );
    this.outsourced = warehouse.outsourced;

    if(this.outsourced) this.headers.push({ text: this.$i18n.t("total"), align: "left", value: "total", width: "100px" })
    this.headers.push({text: "",centered: true,value: "actions", width: "40px"});

    this.currency = warehouse.currency.symbol;
    if (this.options.inventory_id) this.getInventryDetails();
  },
  methods: {
    getInventryDetails() {
      if (this.$can("read", "purchasing.inventory")) {
        this.inventory_loading = true;
        this.$axios
          .get(
            "purchasing/inventory/content/" + (this.options.inventory_id || "") + "?processed=" + (this.options.processed ? 1 : 0) + "&warehouse_id=" + (this.options.warehouse_id)
          )
          .then(res => {
            this.items = res.data.list ? res.data.list : [];
            if (res.data.shipping_cost) this.shipping = res.data.shipping_cost;
            this.summary = res.data.summary;
            for (let k = 0; k < this.items.length; k++) {
              const dateParts = this.items[k].expiry.split("-"); 
              const year = parseInt(dateParts[0]);
              const month = parseInt(dateParts[1]);
              const day = parseInt(dateParts[2]);
              this.items[k].date = new Date(year, month - 1, day);
            }
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.inventory_loading = false));
      }
    },
    addProduct(p) {
      if (p.id) {
        this.items.unshift({
          added: true,
          prod_ref_id: p.id,
          attribute: p.flavor ? "flavors" : "colors",
          expiry: null,
          name: p.label,
          purchase_price: null,
          stock_in: null
        });
      }
    },
    setDate(item) {
      const timezoneOffset = item.date.getTimezoneOffset();
      item.date.setMinutes(item.date.getMinutes() - timezoneOffset); // adjust date to timezone
      item.expiry = item.date.toISOString().slice(0, 10);
    },
    saveInventory() {
      this.save_loading = true;
      let lines = this.items.map(item => {
        return {
          id: item.prod_ref_id,
          price: item.purchase_price,
          expiry: item.attribute === "flavors" ? item.expiry : null,
          lines:
            (item.lines && item.lines.reduce((arr, l) => [...arr, l.id], [])) ||
            null,
          mvt: item.stock_in
        };
      });

      let url;
      if (this.options.inventory_id) {
        url = "purchasing/inventory/content/" + this.options.inventory_id;
      } else {
        url = "purchasing/inventory/content/" + this.name;
      }
      this.$axios({
        method: "PUT",
        url: url,
        data: {
          warehouse_id: this.options.warehouse_id,
          cost: this.shipping,
          lines: lines,
          notification: this.notification
        }
      })
        .then((res) => {
          if (this.options.add) this.$root.notify(this.$t("successfully_created"), "is-success");
          else this.$root.notify(this.$t("successfully_updated"), "is-success");
          this.$root.closeDrawer("main");
          if (this.options.add) this.$bus.$emit("create-inventory", {id: this.options.inventory_id, item: res.data});
          else this.$bus.$emit("update-inventory", this.options.inventory_id);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.save_loading = false));
    },
    deleteItem(p) {
      if (!p.lines) {
        let index = this.items.findIndex(
          prod => prod.prod_ref_id == p.prod_ref_id
        );
        this.items.splice(index, 1);
        this.tempProductName = p.name;
      } else {
        this.tempProductName = p.name;
        this.TempLineId = p.prod_ref_id;
        this.TempLines = p.lines.reduce(
          (arr, l) => [...arr, { ...l, delete: true }],
          []
        );
        this.showdeleteModal = true;
      }
    },
    cancelDelete() {
      this.tempProductName = null;
      this.showdeleteModal = false;
      this.TempLines = [];
      this.TempLineId = null;
    },
    SendDeleteRequest() {
      let tempLine = this.TempLines.filter(l => l.delete).reduce(
        (arr, l) => [...arr, l.id],
        []
      );

      this.$axios
        .delete(`/purchasing/inventory/lines/${tempLine.join(",")}`)
        .then(() => {
          let index = this.items.findIndex(
            p => p.prod_ref_id == this.TempLineId
          );
          if (tempLine.length == this.TempLines.length) {
            this.items.splice(index, 1);
          } else {
            this.items[index].lines = this.items[index].lines.filter(
              l => tempLine.indexOf(l.id) == -1
            );
          }
          this.cancelDelete();
        });
    }
  }
};
</script>

